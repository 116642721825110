import React from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import logo from "../../assets/nav-logo-wh.png";
import "./FooterStyles.css";
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";
import { AiOutlinePhone } from 'react-icons/ai';
import DHCS from "../../assets/DHCS-logo.png";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="top">

            <div className="logo-footer">
              <img src={logo} alt="Auburn Rehab Logo" loading="lazy"/>
            </div>

            <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
              <BsFillArrowUpCircleFill className="icon" />
            </LinkRoll>
          </div>



          <div className="col-container">
            <div className="col">
              <h3>COMPANY</h3>
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/mission">
                  <p>Our Mission</p>
                </Link>
              </LinkRoll>

              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/about-us">
                  <p>About Us</p>
                </Link>
              </LinkRoll>

            </div>

            <div className="col">
              <h3>Navigation</h3>
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/">
                  <p>Home</p>
                </Link>
              </LinkRoll>

              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/insurance">
                  <p>Insurance</p>
                </Link>
              </LinkRoll>


              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/contact">
                  <p>Contact</p>
                </Link>
              </LinkRoll>
            </div>


            <div className="col">
              <h3>programs</h3>
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/mental-health">
                  <p>Mental Health Programs</p>
                </Link>
              </LinkRoll>

              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/substance-abuse">
                  <p>Substance Abuse Programs</p>
                </Link>
              </LinkRoll>

              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/jobs">
                  <p>Job Assistance Program</p>
                </Link>
              </LinkRoll>
            </div>


            <div className="col">
              <h3>Treatment</h3>
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/php">
                  <p>Partial Hospitalization Program</p>
                </Link>
              </LinkRoll>

              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/iop">
                  <p>Intensive Outpatient Program</p>
                </Link>
              </LinkRoll>

              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to="/op">
                  <p>Outpatient Program</p>
                </Link>
              </LinkRoll>
            </div>




          </div>

          <hr />


{/* LICENSE */}

          <div className="license">
                  <h3>Certified by the State Department of Health Care Services</h3>
                  <p>Certification Number: 340130BP <br />Expiration date: 8/31/2025</p>
                  <br />
                  <a href="https://data.chhs.ca.gov/dataset/sud-recovery-treatment-facilities"
                  target="_blank"
                  rel="noreferrer">( View License & Certification Information here ).</a>
                      <div className='dhcsLogo'>
                          <img
                          src={DHCS}
                          alt="DHCS Logo"
                          loading="lazy"
                          />
                      </div>
              </div>

{/* END OF LICENSE */}



          <div className="copyright">
            <p>
              &copy; 2024 Auburn Rehab | All rights reserved
            </p>
            <p className="phoneNumber"><span><AiOutlinePhone /></span><a href="tel:3234195997"> +1 (323) 419-5997</a></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
